@tailwind base;
@tailwind components;
@tailwind utilities;


$fa-font-path: "/assets/webfonts";
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light";

@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  --great: theme(colors.green.600);
  --good: theme(colors.green.400);
  --ok: theme(colors.brand);
  --poor: theme(colors.yellow.500);
  --bad: theme(colors.red.600);
  --brand: theme(colors.brand);
}


.icon-parent {
  @import "node_modules/@fortawesome/fontawesome-pro/scss/brands";
}

// tooltip
*[data-tooltip] {
  position: relative;

  &:hover,
  &.active {
    &::after {
      content: attr(data-tooltip);
      font-weight: normal;
      @apply font-inter absolute inline-block p-2 rounded bg-black/70 text-white shadow z-50 left-[50%] top-0 whitespace-nowrap leading-loose text-xs translate-x-[-50%] translate-y-[-110%];
    }
  }

  &[data-tooltip-position="left"] {
    &::after {
      @apply top-[50%] translate-y-[-50%] translate-x-[-110%] left-0;

    }
  }

  &.tooltip-wrap {
    white-space: pre-wrap;
  }
}

.hover-solid:hover {
  font-weight: 900 !important;
}

.tooltip {
  width: max-content;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--brand);
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 90%;
  z-index: 99;
  max-width: 450px;
}

.tooltip-arrow {
  position: absolute;
  background: var(--brand);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}


// ng-select
ng-select {
  input {
    @apply text-white #{!important};
  }

  .ng-select-container {
    @apply flex min-h-[36px] w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-brand/50 sm:text-sm sm:leading-6 #{!important};
  }

  &.ng-invalid.ng-touched > .ng-select-container {
    @apply ring-red-500 #{!important};
  }

  .ng-option {
    @apply bg-slate-900 text-sm text-gray-300 text-left #{!important};
    &:hover {
      @apply bg-slate-800 #{!important};
    }
  }

  .ng-dropdown-panel {
    @apply  border border-white/10 bg-slate-900 #{!important};
  }

  .ng-value {
    @apply text-white #{!important};
  }

  &.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    @apply bg-brand #{!important};
  }

  .ng-value-icon {
    @apply border-orange-500 hover:bg-orange-700 #{!important};
  }
}

// broken images
img:-moz-broken{
  opacity: 0;
}


img {
  position: relative;
}

img::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/assets/images/passbot-logo.svg'), rgb(15, 23, 42);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
